import React, { useState } from "react";
import logo from "../icons/logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "./Button";
import CachedImage from "./CachedImage";
import { scrollTo } from "../js/utils";
import { ReactComponent as LibraryIcon } from "../icons/library.svg";
import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as PlansIcon } from "../icons/plans.svg";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <nav
      className="sticky top-0 bg-black"
      id="navbar"
      style={{ zIndex: 9999999999 }}
    >
      <div className="px-5 md:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl py-3.5 mx-auto">
          <Link to="/" id="logo">
            <CachedImage src={logo} alt="logo" className="w-[100px]" />
          </Link>
          <button
            onClick={toggleSidebar}
            type="button"
            className="inline-flex items-center justify-center w-10 h-10 p-2 text-sm rounded-lg text-secondary-500 md:hidden focus:outline-none focus:ring-2 focus:ring-secondary-200 dark:text-secondary-400 dark:hover:bg-secondary-700 dark:focus:ring-secondary-600"
            aria-controls="navbar-solid-bg"
            aria-expanded={isSidebarOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto">
            <div className="flex flex-col mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:items-center md:mt-0 md:border-0 md:bg-transparent bg-secondary-800 border-secondary-700">
              <Link
                to="/"
                className="block px-3 py-2 text-white rounded md:p-0 md:bg-transparent md:dark:bg-transparent"
                aria-current="page"
              >
                Home
              </Link>
              <Link
                to="/blog"
                className="block px-3 py-2 text-white rounded md:p-0 md:hover:bg-transparent md:border-0"
              >
                Blog
              </Link>
              {/* <Link
                to="/plans"
                className="block px-3 py-2 text-white rounded md:p-0 md:hover:bg-transparent md:border-0"
              >
                Plans
              </Link> */}
              <button
                onClick={() => (pathname === "/" ? scrollTo() : navigate("/"))}
                className="block px-4 py-1.5 font-semibold text-black bg-primary rounded-full"
                style={{ fontSize: "15px" }}
              >
                Download App
              </button>
              {/* <a
                href="https://app.stridez.io/"
                target="_blank"
                rel="noreferrer"
              >
                <Button isSmall={true} hoverAnimation={true} onClick={() => scrollTo()}>
                  Download App
                </Button>
              </a> */}
            </div>
          </div>
        </div>
      </div>

      {isSidebarOpen && (
        <div
          onClick={closeSidebar}
          className="fixed inset-0 bg-black opacity-50"
          style={{ zIndex: 9999999999 }}
        ></div>
      )}

      <div
        className={`fixed top-0 right-0 w-64 h-screen p-5 overflow-y-auto transition-transform duration-200 bg-white rounded-s-xl dark:bg-secondary-900 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
        tabIndex="-1"
        style={{ zIndex: 9999999999 }}
        aria-labelledby="drawer-navigation-label"
      >
        <CachedImage src={logo} alt="logo" />
        <button
          onClick={closeSidebar}
          type="button"
          aria-controls="drawer-navigation"
          className="text-secondary-400 bg-transparent rounded-lg text-sm p-1.5 absolute top-5 end-5 inline-flex items-center hover:bg-secondary-600 hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="w-full py-6 overflow-y-auto">
          <div className="flex flex-col w-full gap-1 space-y-2 font-medium">
            <Link
              to="/"
              onClick={closeSidebar}
              className="flex items-center w-full p-2 font-semibold text-white rounded-lg hover:bg-secondary-700"
            >
              <HomeIcon className="mr-3 size-6" />
              Home
            </Link>
            <Link
              to="/blog"
              onClick={closeSidebar}
              className="flex items-center w-full p-2 font-semibold text-white rounded-lg hover:bg-secondary-700"
            >
              <LibraryIcon className="mr-3 size-6" />
              Blog
            </Link>
            {/* <Link
              to="/plans"
              onClick={closeSidebar}
              className="flex items-center w-full p-2 font-semibold text-white rounded-lg hover:bg-secondary-700"
            >
              <PlansIcon className="mr-3 size-6" />
              Plans
            </Link> */}
            <Button
              onClick={() => (pathname === "/" ? scrollTo() : navigate("/"))}
              className="w-full rounded-full"
              isSmall={true}
              style={{ borderRadius: "100px" }}
            >
              Download App
            </Button>
            {/* <a
              href="https://app.stridez.io/"
              target="_blank"
              rel="noreferrer"
              className="w-full"
            >
              <Button className="w-full" roundedFull isSmall={true}>
                Open App
              </Button>
            </a> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
